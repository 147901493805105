import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { BowButton } from "../bow-button";

export const GetMySantaAudioCallButton: React.FC = () => {
  const history = useHistory();

  const handleClick = useCallback((): void => history.push("/santa-call/audio"), [history]);

  return (
    <BowButton size={430} onClick={handleClick}>
      Get My Santa Audio Call
    </BowButton>
  );
};
