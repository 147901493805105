import { CallType } from "@santa/common/lib/products/calls";
import { Col, Container, Row, Visible } from "react-grid-system";
import styled from "styled-components";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import { BowButton } from "../../../atoms/bow-button";
import { ButtonLink } from "../../../atoms/button-link";
import { BackLink } from "../../../molecules/back-link";
import { ProductAddOnPanel } from "../../../organisms/product-add-on-panel";
import { MainWrapper } from "../../../atoms/containers/main-wrapper";
import { device } from "../../../../utils/media-queries";
import { MobileBackLink } from "../../../molecules/mobile-back-link";
import { CallCertificateAddOnPanel } from "../../../organisms/call-certificate-add-on-panel";
import { StageTitle } from "../../../atoms/stage-title";
import { DataLoadedContainer } from "../../../control/data-loaded-container";
import { ProductAlphaId } from "../../../../types/graphql";

import { IAddOn, useData } from "./use-data";

const PanelCol = styled(Col)`
  display: flex;
  padding-bottom: 24px;
`;

const LinksRow = styled(Row)`
  margin-top: 31px;
`;

const ButtonCol = styled(Col)`
  display: flex;
  flex-direction: row;

  @media ${device.laptopL} {
    justify-content: flex-end;

    & > * {
      margin-left: 30px;
    }
  }

  @media ${device.mobileS} {
    justify-content: space-between;
  }
`;

interface IProps {
  type: CallType;
}

export const CallsAddOns: React.FC<IProps> = ({ type }) => {
  const {
    isLoading,
    data,
    isLightboxOpen,
    lightboxImageIndex,
    callQuantity,
    isContinueDisabled,
    handleAddToBasketWithAddOns,
    handleAddToBasketWithoutAddOns,
    handleToggleAddOn,
    handleClickBack,
    handleOpenLightbox,
    handleCloseLightbox,
    handleClickPreviousImage,
    handleClickNextImage,
    setAddress,
    setIsAddressValid,
    refetch,
  } = useData(type);

  const handleClickPanel = (alphaId: ProductAlphaId) => () => handleToggleAddOn(alphaId);
  const handleClickImage = (index: number) => () => handleOpenLightbox(index);

  const getPanel = (p: IAddOn, index: number): JSX.Element => {
    if (p.alphaId === ProductAlphaId.CALL_CERTIFICATE) {
      return (
        <CallCertificateAddOnPanel
          title={p.title}
          description={p.description}
          isSelected={p.isSelected}
          onClick={handleClickPanel(p.alphaId)}
          imageUrl={p.imageUrl}
          price={p.price}
          onClickImage={handleClickImage(index)}
          onChangeAddress={setAddress}
          onValidation={setIsAddressValid}
          callQuantity={callQuantity}
        />
      );
    }

    return (
      <ProductAddOnPanel
        title={p.title}
        description={p.description!}
        isSelected={p.isSelected}
        onClick={handleClickPanel(p.alphaId)}
        imageUrl={p.imageUrl}
        price={p.price}
        isPopular={p.isPopular}
        onClickImage={handleClickImage(index)}
      />
    );
  };

  return (
    <MainWrapper>
      <Container>
        <MobileBackLink onClick={handleClickBack}>Back</MobileBackLink>
        <Row>
          <Col>
            <StageTitle totalStages={3} currentStage={3}>
              <Visible sm md lg xl>
                Make the Santa Call more special
              </Visible>
              <Visible xs>Magical add-ons</Visible>
            </StageTitle>
          </Col>
        </Row>

        <DataLoadedContainer isLoading={isLoading} refetch={refetch}>
          {data && (
            <>
              <Row>
                {data.addOns.map((p, index) => (
                  <PanelCol key={index}>{getPanel(p, index)}</PanelCol>
                ))}
              </Row>
              <LinksRow align="center">
                <Visible md lg xl>
                  <Col md={6}>
                    <BackLink label="Back" onClick={handleClickBack} />
                  </Col>
                </Visible>
                <ButtonCol md={6}>
                  <ButtonLink label="Skip" onClick={handleAddToBasketWithoutAddOns} />
                  <BowButton
                    size={330}
                    onClick={handleAddToBasketWithAddOns}
                    disabled={isContinueDisabled}
                  >
                    Add to Basket
                  </BowButton>
                </ButtonCol>
              </LinksRow>

              {isLightboxOpen && (
                <Lightbox
                  mainSrc={data.images[lightboxImageIndex]}
                  nextSrc={data.images[(lightboxImageIndex + 1) % data.images.length]}
                  prevSrc={
                    data.images[(lightboxImageIndex + data.images.length - 1) % data.images.length]
                  }
                  onCloseRequest={handleCloseLightbox}
                  enableZoom={false}
                  onMovePrevRequest={handleClickPreviousImage}
                  onMoveNextRequest={handleClickNextImage}
                />
              )}
            </>
          )}
        </DataLoadedContainer>
      </Container>
    </MainWrapper>
  );
};
