import { useMemo, useEffect, useCallback } from "react";
import { useReactiveVar } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { History } from "history";

import { getLocaleForApi } from "../../../../../utils/graphql";
import { config } from "../../../../../config";
import { ISurcharge } from "../../../../molecules/surcharge-notice";
import {
  useLetterAddOnsQuery,
  LetterAddOnsQuery,
  ProductAlphaId,
} from "../../../../../types/graphql";
import {
  letterCreateState,
  ILetterCreateState,
  letterState,
} from "../../../../../model/graphql/cache";
import { routePaths } from "../../../../../model/route";
import { useAddOnsLightbox } from "../../../../../hooks/add-ons/use-add-ons-lightbox";

type SurchargeQuery = NonNullable<LetterAddOnsQuery["product"]>["internationalSurcharge"];

interface ISurcharges {
  international?: ISurcharge;
  packing?: ISurcharge;
}

interface IAddOn {
  alphaId: ProductAlphaId;
  title: string;
  description: string;
  imageUrl: string;
  price: number;
  isPopular: boolean;
  isSelected: boolean;
  surcharges: ISurcharges;
}

interface IData {
  addOns: IAddOn[];
  images: string[];
  surcharges: ISurcharge[];
}

const getSurcharge = (surcharge: SurchargeQuery | null): ISurcharge | undefined => {
  if (!surcharge) {
    return;
  }

  const { price, title, alphaId } = surcharge;

  if (!price || !title || !alphaId) {
    return;
  }

  return { price, title, alphaId: alphaId as ProductAlphaId };
};

type NotNulled<T> = { [P in keyof T]: NonNullable<T[P]> };

const filterOutNulled = <T extends object>(o: T): o is NotNulled<T> => {
  for (const value in o) {
    if (value === null) {
      return false;
    }
  }

  return true;
};

const mapData = (
  query: LetterAddOnsQuery | undefined,
  savedData: ILetterCreateState,
  selectedAddons: ProductAlphaId[] | undefined,
): IData | undefined => {
  if (query && savedData) {
    const { recipient } = savedData;
    const { product } = query;

    if (recipient) {
      const { addressCountryUrn } = recipient;

      if (product) {
        const addOns = product.addOns
          .map(a => ({
            ...a,
            surcharges: {
              international: getSurcharge(a.internationalSurcharge),
              packing: getSurcharge(a.postagePackingSurcharge),
            },
          }))
          .map(({ alphaId, title, description, image, price, isPopular, surcharges }) => ({
            alphaId,
            title,
            description,
            image,
            price,
            isPopular,
            surcharges,
          }))
          .filter(filterOutNulled)
          .map<IAddOn>(({ alphaId, title, description, image, price, isPopular, surcharges }) => ({
            alphaId: alphaId as ProductAlphaId,
            title,
            description,
            imageUrl: image.url,
            price,
            isPopular,
            surcharges,
            isSelected: selectedAddons?.includes(alphaId as ProductAlphaId) || false,
          }));

        const surchargeObject = addOns
          .filter(a => a.isSelected)
          .reduce<ISurcharges>((acc, a) => {
            // item has international surcharge and we are sending to a different country
            if (a.surcharges.international && addressCountryUrn !== config.defaultCountry) {
              // we have not found a charge yet or this one is a greater price
              if (
                !acc.international ||
                a.surcharges.international.price > acc.international.price
              ) {
                acc.international = a.surcharges.international;
              }
            }
            // item has packing surcharge
            if (a.surcharges.packing) {
              // we have not found a charge yet or this one is a greater price
              if (!acc.packing || a.surcharges.packing.price > acc.packing.price) {
                acc.packing = a.surcharges.packing;
              }
            }

            return acc;
          }, {});

        const surcharges = (Object.keys(surchargeObject) as (keyof ISurcharges)[])
          .sort()
          .map(k => surchargeObject[k])
          .filter<ISurcharge>((k): k is ISurcharge => !!k);

        return {
          addOns,
          images: addOns.map(p => `${p!.imageUrl!}?w=600`),
          surcharges,
        };
      }
    }
  }

  return undefined;
};

const addToBasketFactory =
  (
    history: History<unknown>,
    withAddOns: boolean,
    query: LetterAddOnsQuery | undefined,
    surcharges?: ISurcharge[],
  ) =>
  async (): Promise<void> => {
    if (query) {
      const surchargeIds = surcharges ? surcharges.map(s => s.alphaId) : [];

      if (
        query.product?.internationalSurcharge?.alphaId &&
        letterCreateState().recipient?.addressCountryUrn !== config.defaultCountry
      ) {
        surchargeIds.push(query.product.internationalSurcharge.alphaId as ProductAlphaId);
      }

      letterState().addToBasket(withAddOns, surchargeIds);

      history.push("/basket");
    }
  };

interface IUseData {
  isLoading: boolean;
  data?: IData;
  isLightboxOpen: boolean;
  lightboxImageIndex: number;
  refetch?(): void;
  handleClickBack(): void;
  handleToggleAddOn(a: ProductAlphaId): void;
  handleAddToBasketWithAddOns(): void;
  handleAddToBasketWithoutAddOns(): void;
  handleOpenLightbox(index: number): void;
  handleCloseLightbox(): void;
  handleClickPreviousImage(): void;
  handleClickNextImage(): void;
}

/**
 * Hook to get data for letter addons
 */
export const useData = (): IUseData => {
  const history = useHistory();
  const currentState = useReactiveVar(letterCreateState);

  useEffect(() => {
    if (!currentState.content) {
      history.push(routePaths.letter.content);
    }
  }, [currentState, history]);

  const {
    data: queryData,
    loading: isLoading,
    refetch,
  } = useLetterAddOnsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      alphaId: ProductAlphaId.SANTA_LETTER,
      locale: getLocaleForApi(),
    },
  });

  const data = useMemo(
    () => mapData(queryData, currentState, currentState.addOns),
    [queryData, currentState.addOns],
  );

  const {
    isLightboxOpen,
    lightboxImageIndex,
    handleOpenLightbox,
    handleCloseLightbox,
    handleClickPreviousImage,
    handleClickNextImage,
  } = useAddOnsLightbox(data?.images);

  const handleAddToBasketWithAddOns = useCallback(
    addToBasketFactory(history, true, queryData, data?.surcharges),
    [history, data],
  );
  const handleAddToBasketWithoutAddOns = useCallback(
    addToBasketFactory(history, false, queryData),
    [history, queryData],
  );

  const handleClickBack = useCallback(() => history.push(routePaths.letter.content), [history]);

  return {
    isLoading,
    ...(!data && { refetch }),
    data,
    isLightboxOpen,
    lightboxImageIndex,
    handleAddToBasketWithAddOns,
    handleAddToBasketWithoutAddOns,
    handleToggleAddOn: letterState().toggleAddOn,
    handleClickBack,
    handleOpenLightbox,
    handleCloseLightbox,
    handleClickPreviousImage,
    handleClickNextImage,
  };
};
