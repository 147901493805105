import { useCallback, useContext, useMemo } from "react";
import { useApolloClient } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

import {
  CallType,
  LetterTypePageQuery,
  ProductAlphaId,
  useLetterTypePageQuery,
} from "../../../../types/graphql";
import { VideoContext } from "../../../contexts/video-context";
import { getLocaleForApi } from "../../../../utils/graphql";
import { letterState } from "../../../../model/graphql/cache";
import { LetterHomeOption, routePaths } from "../../../../model/route";
import { useLetterInit } from "../../../../hooks/letter/use-letter-init";
import { mapCmsFaqsToAccordion } from "../../../../utils/faqs";

const mapOptionToProductId = (type: LetterHomeOption): ProductAlphaId | null => {
  switch (type) {
    case "cookieMix":
      return ProductAlphaId.COOKIE_MIX;
    case "magicSnow":
      return ProductAlphaId.MAGIC_SNOW;
    case "plush":
      return ProductAlphaId.SANTA_PLUSH;
    case "santaSack":
      return ProductAlphaId.SANTA_SACK;
    default:
      return null;
  }
};

const mapOptionToBulletText = (type: LetterHomeOption): string => {
  switch (type) {
    case "cookieMix":
      return "The Santa Letter classic with delicious COOKIE MIX";
    case "magicSnow":
      return "Santa Letter classic with MAGIC SNOW";
    case "plush":
      return "Santa Letter classic with PLUSH SANTA TEDDY";
    case "santaSack":
      return "Santa Letter classic + matching customized SANTA SACK";
    default:
      throw new Error("Option not recognised");
  }
};

interface IData {
  baubleContent: string;
  title: string;
  description: string;
  price: number;
  image: string;
  faqs: ReturnType<typeof mapCmsFaqsToAccordion>;
}

const mapData = (
  isEnabled: boolean,
  type: LetterHomeOption,
  product: LetterTypePageQuery["product"],
): IData | undefined => {
  if (
    !product?.price ||
    !product.title ||
    !product.description ||
    !product.productPageDescription ||
    !product.image ||
    !product.faqs
  ) {
    return undefined;
  }

  let price = product.price;
  let description = product.description;
  let baubleContent = product.productPageDescription;
  let image = product.image.url;

  const typeAlphaId = mapOptionToProductId(type);
  if (typeAlphaId) {
    const addOns = product.addOns.filter(a => a.alphaId === typeAlphaId);

    if (addOns.length) {
      const selectedAddOn = addOns[0];
      price += selectedAddOn.price || 0;
      description = `+ ${selectedAddOn.title}`;
      baubleContent = `- ${mapOptionToBulletText(type)}\n${baubleContent}`;

      if (selectedAddOn.image) {
        image = selectedAddOn.image.url;
      }
    }
  }

  const year = new Date().getFullYear();
  const disabledBaubleContent = `
- Santa Letters is now closed for the ${year} season
- You can book an amazing Zoom Video call with Santa for your child or a Video Message From Santa by clicking the button below
`;

  return {
    title: product.title,
    description,
    price,
    image,
    baubleContent: isEnabled ? baubleContent : disabledBaubleContent,
    faqs: mapCmsFaqsToAccordion(product.faqs),
  };
};

type UseData = (
  isEnabled: boolean,
  type: LetterHomeOption,
) => {
  data?: IData;
  isLoading: boolean;
  refetch?(): void;
  handleClickVideoCall(): void;
  handleClickVideoMessage(): void;
  handleClickCreateWithAddOns(addOnIds: ProductAlphaId[]): void;
  handleClickOpenVideo(): void;
  handleClickPreview(): void;
  handleClickOrderNow(): void;
  shouldShowCarousel: boolean;
  t: TFunction;
};

export const useData: UseData = (isEnabled, type) => {
  const video = useContext(VideoContext);
  const client = useApolloClient();
  const { t } = useTranslation();
  const { history, handleClick: handleClickOrderNow } = useLetterInit(type);

  // graphql data
  const {
    data: queryData,
    loading: isLoading,
    refetch,
  } = useLetterTypePageQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      letterAlphaId: ProductAlphaId.SANTA_LETTER,
      locale: getLocaleForApi(),
    },
  });

  const handleClickVideoCall = useCallback((): void => {
    history.push(routePaths.calls[CallType.VIDEO].home);
  }, [history]);

  const handleClickVideoMessage = useCallback((): void => {
    history.push(routePaths.video.home);
  }, [history]);

  const handleClickCreateWithAddOns = useCallback(
    (addOnIds: ProductAlphaId[]): void => {
      letterState().clear();

      if (addOnIds) {
        letterState().setAddOns(addOnIds);
      }

      history.push(routePaths.letter.recipient);
    },
    [client, history],
  );

  const handleClickOpenVideo = useCallback((): void => video.show("letters"), [video]);

  const handleClickPreview = useCallback(
    (): void => history.push(routePaths.letter.templates),
    [history],
  );

  const data = useMemo(
    () => mapData(isEnabled, type, queryData?.product),
    [isEnabled, type, queryData],
  );

  return {
    data,
    isLoading,
    handleClickOrderNow,
    handleClickVideoCall,
    handleClickVideoMessage,
    handleClickCreateWithAddOns,
    handleClickOpenVideo,
    handleClickPreview,
    shouldShowCarousel: type === "classic" && isEnabled,
    ...(!data && { refetch }),
    t,
  };
};
